<template>
  <b-row>
    <b-col md="7" lg="8" xl="8" id="capture">
      <b-row class="stand-name-report" id="header-report" style="display:none;">
        <div class="col-8">
          <b-col class="col-8">
            <h2>
              {{ stand.name }}
            </h2>
          </b-col>
          <!-- <b-col class="col-4 period">
            <span>{{ $t("stats.period") }}</span>
            <span> {{ date.start }} -</span>
            <span> {{ date.end }} </span>
          </b-col> -->
        </div>
      </b-row>
      <b-row class="match-height">
        <b-col class="col-6">
          <statistic-card-with-area-chart
            icon="UserIcon"
            color="info"
            :statistic="total_visitors"
            :statistic-title="$t('stats.visits_stands')"
            :chartData="data.visitors"
            class="stats-card"
          />
        </b-col>
        <b-col class="col-6">
          <statistic-card-with-area-chart
            icon="UserCheckIcon"
            color="success"
            :statistic="total_interactions"
            :statistic-title="$t('stats.interactions_stands')"
            :chartData="data.interactions"
            class="stats-card"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col class="col-12">
          <visitorsGraph
            :daily_visitors_dates="daily_visitors_dates"
            :daily_visitors_data="daily_visitors_data"
            :key="render_component"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <pinInteractions
            :pinpoints="pinpoints_labels"
            :pinpoints_interactions="pinpoints_interactions_count"
            :key="render_component"
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col md="5" lg="4" xl="4">
      <b-card
        :img-src="stand.logo_url"
        img-alt="Profile Cover Photo"
        img-top
        class="card-developer-meetup stand-dx stand-dx-stats"
      >
        <h3>{{ stand.name }}</h3>
        <h6 class="text-muted">
          {{ $t("pavilion_ref") }}
          <span class="stand_type">{{ stand.type.label }}</span>
        </h6>
        <hr class="mb-2" />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
          class="mt-2"
          @click="downLoadReport()"
        >
          {{ $t("stats.download_report") }}
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import Ripple from "vue-ripple-directive";
import visitorsGraph from "./visitors-graph.vue";
import pinInteractions from "./pin-interactions.vue";
import BCardCode from "@core/components/b-card-code";
import { getStand } from "@api/stands.js";
import { getStatistic } from "@api/statistic";
import html2canvas from "html2canvas";

import {
  BBadge,
  BButton,
  BCard,
  BCardText,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormSelect,
  BAvatar,
  BImg,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BModal,
    BBadge,
    BButton,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormSelect,
    BImg,
    BAvatar,
    visitorsGraph,
    StatisticCardWithAreaChart,
    pinInteractions,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        //Valori randomici per poter disegnare la curva a scopo grafico.
        visitors: [
          {
            name: "",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
          },
        ],
        interactions: [
          {
            name: "",
            data: [10, 41, 35, 49, 25, 69, 62, 101, 45],
          },
        ],
        contacts: [
          {
            name: "",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 23],
          },
        ],
      },
      stand: {
        name: null,
        logo_url: null,
        type: { label: null },
        pinpoints: { type: [] },
      },
      render_component: 0,
      total_visitors: 0,
      daily_visitors: [],
      daily_visitors_data: [],
      daily_visitors_dates: [],
      total_interactions: 0,
      pinpoints_interactions_count: [],
      pinpoints_labels: [],
      pinpoints_interactions: [],
      pinpoints_slug: [],
    };
  },
  async created() {
    await this.getSingleStand();
    this.getStatisticData();
  },
  methods: {
    clear_data() {
      this.total_visitors = 0;
      this.total_visitors = 0;
      this.daily_visitors = [];
      this.daily_visitors_data = [];
      this.daily_visitors_dates = [];
    },
    async getSingleStand() {
      let $this = this;
      const data = await getStand(this.$route.params.id);
      this.stand = data;
      //HACK: per modificarlo in modo semplice
      document.getElementById(
        "breadcrumbsPageTitleCustom"
      ).innerHTML = this.stand.name;
      $this.pinpoints_interactions = [];
      for (let i = 0; i < this.stand.pinpoints.length; i++) {
        $this.pinpoints_labels.push($this.stand.pinpoints[i].label);
        $this.pinpoints_slug.push($this.stand.pinpoints[i].type.slug);
        $this.pinpoints_interactions.push({
          type_id: $this.stand.pinpoints[i].type_id,
          interactions: 0,
        });
      }
    },
    getStatisticData() {
      let $this = this;
      this.clear_data();
      let start_date = null;
      let end_date = null;
      if (this.rangeDate) {
        let range_date = this.formatDateToTimestamp(this.rangeDate);
        start_date = range_date[0];
        if (range_date.length > 1) {
          end_date = range_date[1];
        }
      }
      getStatistic(start_date, end_date).then((data) => {
        data = data
          .filter(
            (val) =>
              (val.event == "stand_visit" || val.event == "pinpoint_click") &&
              val.data.stand_id == $this.$route.params.id
          )
          .sort((a, b) =>
            a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0
          );
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let date = $this.convertTimeStampToDate(element.timestamp);
          if (element.event == "stand_visit") {
            $this.total_visitors++;
            let id = $this.daily_visitors.findIndex((x) => x.date === date);
            if (id == -1) {
              let item = {
                date: date,
                count: 1,
              };
              $this.daily_visitors.push(item);
            } else {
              $this.daily_visitors[id].count++;
            }
          }
          if (element.event == "pinpoint_click") {
            $this.total_interactions++;
            let id = $this.pinpoints_interactions.findIndex(
              (x) => x.type_id == parseInt(element.data.type_id)
            );
            if (id > -1 && id < $this.pinpoints_interactions.length) {
              $this.pinpoints_interactions[id].interactions++;
            }
          }
        }
        $this.setDataForGraph();
        $this.forceUpdate();
      });
    },
    convertTimeStampToDate(timestamp) {
      return new Date(timestamp * 1000).toLocaleDateString("it-IT");
    },
    formatDateToTimestamp(date) {
      let date_splitted = date.split(" to ");
      date_splitted[0] = Date.parse(`${date_splitted[0]} 00:00:00`) / 1000;
      if (date_splitted.length > 1) {
        date_splitted[1] = Date.parse(`${date_splitted[1]} 23:59:59`) / 1000;
      }
      return date_splitted;
    },
    setDataForGraph() {
      this.daily_visitors_dates = this.daily_visitors.map(function(item) {
        return item["date"];
      });
      this.daily_visitors_data = this.daily_visitors.map(function(item) {
        return item["count"];
      });
      this.pinpoints_interactions_count = this.pinpoints_interactions.map(
        function(item) {
          return item["interactions"];
        }
      );
    },
    forceUpdate() {
      this.render_component += 1;
    },
    downLoadReport() {
      document.getElementById("header-report").style.display = "";
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        var anchor = document.createElement("a");
        anchor.href = canvas.toDataURL("image/jpg");
        anchor.target = "_blank";
        anchor.download = "Report" + "_" + this.stand.name + ".jpg";
        anchor.click();
        document.getElementById("header-report").style.display = "none";
      });
    },
  },
  computed: {
    rangeDate: function() {
      return this.$store.state.statistics.rangeDate;
    },
  },
  watch: {
    rangeDate: function() {
      this.getStatisticData();
    },
  },
};
</script>

<style>
.period {
  padding-bottom: 14px;
}
.stand-dx-stats {
  top: 160px;
}
</style>
